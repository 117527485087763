import React from 'react';

const IconLoader = () => (
  <svg id="logo" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
    <style>{`
        text {
            font-family: Calibri, Arial, sans-serif;
            font-size: 48px;
        }
    `}</style>
    <title>Loader Logo</title>
    <g>
      <g id="B">
        <text
          x="50"
          y="52"
          fill="currentColor"
          textAnchor="middle"
          dominantBaseline="middle">
          SL
        </text>
      </g>

      <path
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 50, 5
    L 11, 27
    L 11, 72
    L 50, 95
    L 89, 73
    L 89, 28 z"
      />
    </g>
  </svg>
);

export default IconLoader;
